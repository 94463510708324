/* eslint-disable object-curly-spacing */
import { Control } from 'ol/control';

class SearchControl extends Control {
  /**
   * @param {Object} [opt_options] Control options.
   */
  constructor(options) {
    super({
      element: options.element,
      target: options.target,
    });
  }
}

export default SearchControl;
