/* eslint-disable no-shadow */
import Map from 'ol/Map';
import { TileArcGISRest, Vector as VectorSource } from 'ol/source';
import { Group as GroupLayer, Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import View from 'ol/View';
import { Control, MousePosition, ScaleLine, defaults as defaultControls } from 'ol/control';
import { format } from 'ol/coordinate';
import { toLonLat, fromLonLat } from 'ol/proj';
import Overlay from 'ol/Overlay';
import { unByKey } from 'ol/Observable';
import { WKT } from 'ol/format';
import { Circle as CircleStyle, Icon as IconStyle, Fill, Stroke, Style } from 'ol/style';
import { Draw as DrawingInteraction } from 'ol/interaction';
import LayerSwitcher from 'ol-layerswitcher';
import { Modal } from 'bootstrap';
import SearchControl from '@/mixins/searchControl';
import * as yup from 'yup';

export default {
  methods: {
    addDrawingInteraction() {
      this.drawingInteraction = new DrawingInteraction({
        source: this.drawingVectorSource,
        style: new Style({
          image: new CircleStyle({
            radius: 6,
            stroke: new Stroke({ color: 'gray', width: 2 }),
            fill: new Fill({ color: 'rgba(0, 0, 0, 0)' }),
          }),
        }),
        type: 'Point',
      });
      this.drawingInteraction.on('drawstart', this.onDrawStart);
      this.burnMap.addInteraction(this.drawingInteraction);
    },
    async getBurnModel() {
      console.debug('Enter getBurnModel()');

      this.modelLoading = true;

      const coordinates = [...this.lonLat];

      // this.reverseGeocodeResult = null;
      this.$store.dispatch('BurnArea/getReverseGeocodeResult', coordinates).then(() => {

        this.burnModel = {
          name: null,
          burnWindow: null,
          plannedAcres: null,
          state: null,
          locality: null,
          burnStatus: 'Active',
          priority: 'Normal',
          geometry: { type: 'Point', coordinates },
        };

        this.burnModelSchema = {
          name: yup.string().required('Burn name is required').min(1).max(50),
          burnWindow: (value) => {
            if (value) {
              return true;
            }
            return 'You must choose a burn window';
          },
          plannedAcres: yup.number('Required').moreThan(0).typeError('Please enter a number greater than 0'),
          // state: yup.string().required('State is required'),
          locality: yup.string().required('Locality is required'),
        };

        if (this.reverseGeocodeResult != null) {
          this.burnModel.state = this.reverseGeocodeResult.address.Region;
          this.burnModel.locality = this.reverseGeocodeResult.address.Subregion;
        }

        console.debug('this.burnModel.state: ', this.burnModel.state);
        console.debug('this.burnModel.locality: ', this.burnModel.locality);

        this.modelLoading = false;
      });

      console.debug('Exit getBurnModel()');
    },
    getBurnModelLonLat() {
      let longitude = Number(this.burnModel.geometry.coordinates[0]);
      let latitude = Number(this.burnModel.geometry.coordinates[1]);
      longitude = Math.round((longitude * 10000)) / 10000;
      latitude = Math.round((latitude * 10000)) / 10000;
      return `${longitude}, ${latitude}`;
    },
    getBurnStyle(feature) {
      const burnStatus = feature.get('burnStatus');
      const readyToBurn = feature.get('readyToBurn');
      if (burnStatus === 'Active' && !readyToBurn) {
        return this.burnStyleActive;
      }
      if (burnStatus === 'Active' && readyToBurn) {
        return this.burnStyleActiveReadyToBurn;
      }
      if (burnStatus !== 'Active') {
        return this.burnStyleInactive;
      }
      return this.burnStyleUnspecified;
    },
    isSaveBurnDisabled() {
      // console.debug('Enter isSaveBurnDisabled()');

      // console.debug('this.burnModel: ', this.burnModel);
      // console.debug('this.burnModelSchema:', this.burnModelSchema);

      if (!this.burnModel.name) return true;
      if (!this.burnModel.burnWindow) return true;
      if (!this.burnModel.plannedAcres) return true;
      if (!this.burnModel.state) return true;
      if (!this.burnModel.locality) return true;

      // console.debug('Exit isSaveBurnDisabled()');
      return false;
    },
    load() {
      this.formLoading = true;
      this.$store.dispatch('App/fetchBurnMapConfig').then(() => {
        this.$store.dispatch('App/fetchBurnUnitMapConfig').then(() => {
          this.loadBurnMap();
          this.loadBurnGroup();
          this.loadDrawingInteraction();
          this.moveView();
          this.formLoading = false;
        });
      });
    },
    loadBaseLayers() {

      const baseMapsGroup = this.burnMapConfig.layerGroups.find((e) => e.key === 'grp_basemaps');
      const tileLayers = [];

      baseMapsGroup.layers.forEach((layer) => {

        if (!Array.isArray(layer)) {

          const baseMapLayer = this.burnMapConfig.layers.find((e) => e.key === layer);

          const url = baseMapLayer.xyz.endpoints[0].url;
          const source = new TileArcGISRest({ url });

          const tileLayer = new TileLayer({
            title: baseMapLayer.name,
            type: 'base',
            visible: false,
            source,
          });

          tileLayers.push(tileLayer);

        } else {

          const baseMapLayers = [];
          const subLayers = [];

          layer.forEach((subLayer) => {

            const baseMapLayer = this.burnMapConfig.layers.find((e) => e.key === subLayer);

            const url = baseMapLayer.xyz.endpoints[0].url;
            const source = new TileArcGISRest({ url });

            const subTileLayer = new TileLayer({
              source,
            });

            baseMapLayers.push(baseMapLayer);

            subLayers.push(subTileLayer);
          });

          const baseMapLayerGroup = new GroupLayer({
            title: baseMapLayers[0].name,
            type: 'base',
            combine: true,
            visible: false,
            layers: subLayers,
          });

          tileLayers.push(baseMapLayerGroup);
        }

      });

      tileLayers.reverse();
      tileLayers[tileLayers.length - 1].set('visible', true);

      return new GroupLayer({
        title: 'Basemaps',
        layers: tileLayers,
        visible: true,
      });
    },
    loadBurnMap() {

      const controls = this.loadControls();
      const layers = this.loadBaseLayers();
      const overlays = this.loadOverlays();
      const target = 'burnMap';
      const view = this.loadView();

      this.burnMap = new Map({
        controls,
        layers: [layers],
        overlays,
        target,
        view,
      });

      this.layerSwitcher = new LayerSwitcher({
        groupSelectStyle: 'children',
        reverse: true,
      });

      this.burnMap.addControl(this.layerSwitcher);

      this.infoPopupClickEventKey = this.burnMap.on('singleclick', this.onClickMapInfoPopup);

    },
    loadBurnGroup() {
      this.loadBurnStyles();
      this.inactiveBurnsLayer = this.loadBurns('Inactive');
      this.activeBurnsLayer = this.loadBurns('Active');
      const burnsGroupLayer = new GroupLayer({
        title: 'My Burns',
        layers: [this.inactiveBurnsLayer, this.activeBurnsLayer],
        visible: true,
      });
      this.burnMap.addLayer(burnsGroupLayer);
    },
    loadBurns(burnStatus) {
      console.debug('Enter loadBurns()');

      console.debug('this.myBurns: ', this.myBurns);
      console.debug('this.myBurnConditions: ', this.myBurnConditions);

      const wkt = new WKT();
      const wktFeatures = [];

      this.myBurns.filter(
        (myBurn) => myBurn.fields.burnStatus === burnStatus,
      ).forEach((myBurn) => {

        const feature = wkt.readFeature(
          myBurn.fields.Geometry.wkt,
          {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857',
          },
        );
        feature.setId(myBurn.id);
        const properties = {
          id: myBurn.id,
          name: myBurn.name,
          burnStatus: myBurn.fields.burnStatus,
        };
        const myBurnCondition = this.myBurnConditions.find((myBurnCondition) => {
          return myBurnCondition.burnAreaId === myBurn.id;
        });
        if (myBurnCondition) {
          properties.readyToBurn = myBurnCondition.readyToBurn;
        }
        feature.setProperties(properties);
        wktFeatures.push(feature);
      });

      const vectorSource = new VectorSource({
        features: wktFeatures,
      });

      console.debug('Exit loadBurns()');
      return new VectorLayer({
        title: `${burnStatus}`,
        visible: true,
        source: vectorSource,
        style: this.getBurnStyle,
      });
    },
    loadBurnStyles() {

      // https://stackoverflow.com/questions/37829941/use-svg-icon-as-marker-in-openlayers

      let svg = '<svg xmlns="http://www.w3.org/2000/svg" height="512" width="512"><path class="" stroke="white" stroke-width="30" fill="rgb(131, 146, 58)" d="M323.56 51.2c-20.8 19.3-39.58 39.59-56.22 59.97C240.08 73.62 206.28 35.53 168 0 69.74 91.17 0 209.96 0 281.6 0 408.85 100.29 512 224 512s224-103.15 224-230.4c0-53.27-51.98-163.14-124.44-230.4z"></path></svg>';
      this.burnStyleActive = new Style({
        image: new IconStyle({
          opacity: 1,
          src: `data:image/svg+xml;base64,${window.btoa(svg)}`,
          scale: 0.05,
        }),
      });

      svg = '<svg xmlns="http://www.w3.org/2000/svg" height="512" width="512"><path class="" fill="red" d="M323.56 51.2c-20.8 19.3-39.58 39.59-56.22 59.97C240.08 73.62 206.28 35.53 168 0 69.74 91.17 0 209.96 0 281.6 0 408.85 100.29 512 224 512s224-103.15 224-230.4c0-53.27-51.98-163.14-124.44-230.4zm-19.47 340.65C282.43 407.01 255.72 416 226.86 416 154.71 416 96 368.26 96 290.75c0-38.61 24.31-72.63 72.79-130.75 6.93 7.98 98.83 125.34 98.83 125.34l58.63-66.88c4.14 6.85 7.91 13.55 11.27 19.97 27.35 52.19 15.81 118.97-33.43 153.42z"></path></svg>';
      this.burnStyleActiveReadyToBurn = new Style({
        image: new IconStyle({
          opacity: 1,
          src: `data:image/svg+xml;base64,${window.btoa(svg)}`,
          scale: 0.05,
        }),
      });

      this.burnStyleInactive = new Style({
        image: new CircleStyle({
          radius: 10,
          stroke: new Stroke({ color: 'black', width: 1 }),
          fill: new Fill({ color: 'rgba(191, 191, 191, 1.00)' }),
        }),
      });

      this.burnStyleUnspecified = new Style({
        image: new CircleStyle({
          radius: 10,
          stroke: new Stroke({ color: 'black', width: 1 }),
          fill: new Fill({ color: 'rgba(191, 191, 191, 0.10)' }),
        }),
      });

    },
    loadControls() {

      const mousePositionControl = new MousePosition({
        coordinateFormat: (coordinate) => {
          return format(coordinate, '{y}, {x}', 4);
        },
        projection: 'EPSG:4326',
        undefinedHTML: '&nbsp;',
      });

      const searchControl = new SearchControl({
        element: this.$refs.searchControl,
      });

      const scaleBarControl = new ScaleLine({
        units: 'us',
        bar: true,
        steps: 4,
        text: true,
        minWidth: 140,
      });

      const self = this;

      // eslint-disable-next-line
      const DrawBurnPointControl = /* @__PURE__ */(function (Control) {
        function DrawBurnPointControl(optOptions) {

          const options = optOptions || {};
          const drawBurnPointButton = self.$refs.drawBurnPointButton;
          const cancelBurnPointButton = self.$refs.cancelBurnPointButton;
          const element = document.createElement('div');

          element.className = 'ol-draw-burn-point ol-unselectable';
          element.appendChild(drawBurnPointButton);
          element.appendChild(cancelBurnPointButton);

          Control.call(this, {
            element,
            target: options.target,
          });

          drawBurnPointButton.addEventListener('click', self.onClickBurnPointDrawStart.bind(self), false);
          cancelBurnPointButton.addEventListener('click', self.onClickBurnPointDrawCancel.bind(self), false);
        }

        // eslint-disable-next-line
        if (Control) DrawBurnPointControl.__proto__ = Control;
        DrawBurnPointControl.prototype = Object.create(Control && Control.prototype);
        DrawBurnPointControl.prototype.constructor = DrawBurnPointControl;

        return DrawBurnPointControl;
      }(Control));

      return defaultControls().extend([
        mousePositionControl,
        searchControl,
        scaleBarControl,
        new DrawBurnPointControl(),
      ]);
    },
    loadDrawingInteraction() {
      this.drawingVectorSource = new VectorSource({ wrapX: false });
      this.drawingVectorLayer = new VectorLayer({
        source: this.drawingVectorSource,
        style: new Style({
          image: new CircleStyle({
            radius: 10,
            fill: new Fill({ color: 'cyan' }),
            stroke: new Stroke({ color: 'black', width: 1 }),
          }),
        }),
        visible: true,
      });
      this.burnMap.addLayer(this.drawingVectorLayer);
    },
    loadOverlays() {
      this.infoPopupOverlay = new Overlay({
        element: this.$refs.infoPopupContainer,
        autoPan: true,
        autoPanAnimation: {
          duration: 250,
        },
      });
      this.$refs.infoPopupCloser.onclick = this.onClickInfoPopupClose;
      this.popupOverlay = new Overlay({
        element: this.$refs.popupContainer,
        autoPan: true,
        autoPanAnimation: {
          duration: 250,
        },
      });
      this.$refs.popupCloser.onclick = this.onClickPopupClose;
      return [this.infoPopupOverlay, this.popupOverlay];
    },
    loadView() {
      const extent = this.burnMapConfig.extent;
      const center = [
        (extent.xMax + extent.xMin) / 2,
        (extent.yMax + extent.yMin) / 2,
      ];
      const view = new View({
        center: fromLonLat(center),
        zoom: 6,
      });
      return view;
    },
    moveView() {
      if (this.burnId && this.burnId !== null) {
        this.$store.dispatch('BurnArea/getBurn', this.burnId).then(() => {
          if (this.myBurn && this.myBurn !== null && this.myBurn.fields && this.myBurn.fields !== null && this.myBurn.fields.Geometry && this.myBurn.fields.Geometry !== null) {
            const geometry = this.myBurn.fields.Geometry;
            const wkt = new WKT();
            const feature = wkt.readFeature(
              geometry.wkt,
              { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' },
            );
            this.infoPopupFeatureId = this.myBurn.id;
            this.infoPopupFeatureName = this.myBurn.name;
            this.infoPopupOverlay.setPosition(feature.getGeometry().flatCoordinates);
            this.burnMap.getView().setCenter(feature.getGeometry().flatCoordinates);
            this.burnMap.getView().setZoom(this.burnUnitMapConfig.zoom);
          }
        });
      }
    },
    onClickBurnModelCancel() {
      console.debug('Enter onClickBurnModelCancel()');

      console.debug('Exit onClickBurnModelCancel()');
    },
    onClickBurnModelSave(values, { resetForm }) {
      console.debug('Enter onClickBurnModelSave()');

      console.debug('values: ', values);
      console.debug('this.burnModel: ', this.burnModel);

      this.editBurnModal.hide();

      const x = this.burnModel.geometry.coordinates[0];
      const y = this.burnModel.geometry.coordinates[1];
      const type = 'Point';
      const wkt = `POINT (${x} ${y})`;
      const geometry = { type, wkt };

      const burnModel = {
        name: this.burnModel.name,
        fields: {
          burnDate: null,
          burnStatus: this.burnModel.burnStatus,
          burnWindow: this.burnModel.burnWindow,
          geometry: this.burnModel.geometry,
          Geometry: geometry,
          state: this.burnModel.state,
          locality: this.burnModel.locality,
          plannedAcres: this.burnModel.plannedAcres,
          priority: this.burnModel.priority,
          preferredConditions: [],
        },
      };
      console.debug('burnModel: ', burnModel);

      this.$store.dispatch('BurnArea/postBurn', burnModel).then(() => {
        (new Modal(this.$refs.successModal)).show();
      });

      resetForm();

      console.debug('Exit onClickBurnModelSave()');
    },
    onClickBurnPointDrawStart() {
      this.drawing = true;
      this.infoPopupOverlay.setPosition(undefined);
      this.$refs.infoPopupCloser.blur();
      this.$refs.drawBurnPointButton.style.display = 'none';
      this.$refs.cancelBurnPointButton.style.display = 'block';
      this.singleClickEventKey = this.burnMap.on('singleclick', this.onClickMap);
      this.addDrawingInteraction();
    },
    onClickBurnPointDrawCancel() {
      this.drawing = false;
      unByKey(this.singleClickEventKey);
      this.burnMap.removeInteraction(this.drawingInteraction);
      this.drawingVectorSource.clear();
      this.popupOverlay.setPosition(undefined);
      this.$refs.popupCloser.blur();
      this.$refs.drawBurnPointButton.style.display = 'block';
      this.$refs.cancelBurnPointButton.style.display = 'none';
    },
    onClickMap(event) {
      this.lonLat = toLonLat(event.coordinate);
      this.popupOverlay.setPosition(event.coordinate);
    },
    onClickMapInfoPopup(event) {
      this.hideSearches();
      this.infoPopupOverlay.setPosition(undefined);
      this.$refs.infoPopupCloser.blur();
      if (!this.drawing) {
        this.burnMap.forEachFeatureAtPixel(event.pixel, (feature) => {
          this.infoPopupFeatureId = feature.get('id');
          this.infoPopupFeatureName = feature.get('name');
          this.lonLat = toLonLat(event.coordinate);
          this.infoPopupOverlay.setPosition(event.coordinate);
        });
      }
    },
    onClickInfoPopupClose() {
      this.infoPopupOverlay.setPosition(undefined);
      this.$refs.infoPopupCloser.blur();
      return false;
    },
    onClickPopupClose() {
      this.popupOverlay.setPosition(undefined);
      this.$refs.popupCloser.blur();
      return false;
    },
    onClickPopupSave() {

      this.drawing = false;
      unByKey(this.singleClickEventKey);
      this.burnMap.removeInteraction(this.drawingInteraction);
      this.drawingVectorSource.clear();

      this.popupOverlay.setPosition(undefined);
      this.$refs.popupCloser.blur();

      this.$refs.drawBurnPointButton.style.display = 'block';
      this.$refs.cancelBurnPointButton.style.display = 'none';

      this.getBurnModel();

      return false;
    },
    onClickPopupClear() {
      this.drawing = false;
      unByKey(this.singleClickEventKey);
      this.burnMap.removeInteraction(this.drawingInteraction);
      this.drawingVectorSource.clear();
      this.popupOverlay.setPosition(undefined);
      this.$refs.popupCloser.blur();
      this.$refs.drawBurnPointButton.style.display = 'block';
      this.$refs.cancelBurnPointButton.style.display = 'none';
    },
    onClickSuccessModalManageArea() {
      this.$router.push({ name: 'BurnArea', params: { id: this.myNewBurn.id } });
    },
    onDrawStart() {
      this.drawingVectorSource.clear();
    },
    setBurnConditions() {
      console.debug('Enter setBurnConditions()');

      this.myBurnConditions = [];

      this.myBurns.forEach((myBurn) => {

        const myBurnStat = this.myStats.find((e) => e.fields && e.fields.burnAreaId === myBurn.id);
        // console.debug('myBurnStat: ', myBurnStat);
        // console.debug('myBurnStat.name: ', myBurnStat.name);

        const weatherConditionDates = this.getWeatherConditionDates(myBurnStat);

        if (myBurnStat) {

          const weatherConditions = myBurnStat.fields.weatherConditions.map((weatherCondition) => weatherCondition.values).flat().map((e) => {
            const weatherCondition = { ...e };
            weatherCondition.preferredCondition = myBurn.fields.preferredConditions.find((p) => p.name === e.name);
            weatherCondition.preferredConditionSet = this.isPreferredConditionSet(weatherCondition.preferredCondition);
            weatherCondition.preferredConditionMet = this.isPreferredConditionMet(weatherCondition.preferredCondition, weatherCondition);
            return weatherCondition;
          });

          // const burnConditions = [...this.burnConditionDates].map((e) => {
          const burnConditions = weatherConditionDates.map((e) => {

            const burnConditionStartDate = e.startDate;
            // console.debug('burnConditionStartDate: ', burnConditionStartDate);

            const burnConditionWeatherConditions = weatherConditions.filter(
              (weatherCondition) => {
                const weatherConditionStartDate = weatherCondition.startDate;
                // weatherConditionStartDate = this.getGMTDate(new Date(weatherConditionStartDate));
                // console.debug('weatherConditionStartDate: ', weatherConditionStartDate);
                return new Date(weatherConditionStartDate).valueOf() === burnConditionStartDate.valueOf();
              },
            );
            // console.debug('burnConditionWeatherConditions: ', burnConditionWeatherConditions);

            const score = {
              requiredPreferredConditionsSet: burnConditionWeatherConditions.filter((w) => w.preferredCondition.required && w.preferredConditionSet).length,
              requiredPreferredConditionsMet: burnConditionWeatherConditions.filter((w) => w.preferredCondition.required && w.preferredConditionMet).length,
              optionalPreferredConditionsSet: burnConditionWeatherConditions.filter((w) => !w.preferredCondition.required && w.preferredConditionSet).length,
              optionalPreferredConditionsMet: burnConditionWeatherConditions.filter((w) => !w.preferredCondition.required && w.preferredConditionMet).length,
            };

            return {
              startDate: e.startDate,
              endDate: e.endDate,
              weatherConditions: burnConditionWeatherConditions,
              score,
              readyToBurn: this.isScoreReadyToBurn(score),
            };
          });
          // console.debug('burnConditions: ', burnConditions);

          const burnCondition = {
            burnAreaId: myBurn.id,
            preferredConditions: myBurn.fields.preferredConditions,
            burnConditions,
            readyToBurn: burnConditions.filter((bc) => bc.readyToBurn).length > 0,
          };

          this.myBurnConditions.push(burnCondition);
        }
      });

      console.debug('this.myBurnConditions: ', this.myBurnConditions);

      console.debug('Exit setBurnConditions()');
    },
  },
};
