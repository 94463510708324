<style lang="scss" scoped>
  .form-check-input:checked {
      background-color: rgb(127, 63, 20);
      border-color: rgb(164, 141, 41);
  }
  .form-control:focus{
    outline-color: rgb(164, 141, 41)!important;
  }
  .form-select{
    option {
      font-size: 0.875em;
    }
  }
  .modal-header {
    background-color: rgb(127, 63, 20) !important;
    color: white;
  }
  .ol-search {
    background-color: transparent;
    display: inline-block;
    top: 9px;
    right: 48px;
    width: 345px;
    .border {
      border-radius: 10px;
    }
  }
  .ol-search-form {
    display:flex;
    flex-flow:row wrap;
  }
  .ol-search-input {
    border-style: none !important;
    width: 300px;
    font-size: 0.75em;
  }
  .ol-search-input-group{
    background-color: white;
    width: 100%;
  }
  .ol-search-submit {
    background-color: transparent !important;
    color: black !important;
    button{
      svg {
        color: black;
        fill: currentColor;
      }
    }
  }
  .card-search {
    width: 100%;
  }
  .card-search:hover {
    background-color: rgb(235, 235, 235) !important;
  }
  .card-search-content, .card-search-input {
    font-size: 0.75rem;
  }
  .card-search-input:focus {
    outline-style: none !important;
  }
  #popupContainer, #popupContent {
    cursor: default;
  }
  #infoPopupContent, .form-label-small {
    font-size: 0.75em !important;
  }
</style>
<template>
  <!-- <font-awesome-icon icon="layer-group" /> -->
  <!-- <svg xmlns="http://www.w3.org/2000/svg" class="" width="576" height="512" viewBox="0 0 576 512"><path class="" fill="white" d="M12.41 148.02l232.94 105.67c6.8 3.09 14.49 3.09 21.29 0l232.94-105.67c16.55-7.51 16.55-32.52 0-40.03L266.65 2.31a25.607 25.607 0 0 0-21.29 0L12.41 107.98c-16.55 7.51-16.55 32.53 0 40.04zm487.18 88.28l-58.09-26.33-161.64 73.27c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.51 209.97l-58.1 26.33c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 276.3c16.55-7.5 16.55-32.5 0-40zm0 127.8l-57.87-26.23-161.86 73.37c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.29 337.87 12.41 364.1c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 404.1c16.55-7.5 16.55-32.5 0-40z"></path></svg> -->
  <!-- <VForm id="editBurnModal"  v-slot='burnModelValidationResults' class="modal fade" :validation-schema='burnModelSchema' @submit='onSubmitBurnArea' as='div' tabindex="-1"> -->
  <VForm  id="editBurnModal"
          class="modal fade"
          v-slot='{ handleReset }'
          :initial-values='burnModelDefaults'
          :validation-schema='burnModelSchema'
          @submit='onClickBurnModelSave'
          as='div'
          tabindex="-1"
    >
    <!-- v-slot='{ handleSubmit }'  <form id='editBurnForm' @submit='handleSubmit($event, onSubmitBurnArea)'>-->
    <form id='editBurnForm' autocomplete="off">
      <div aria-labelledby="editBurnModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Save Burn Unit Details</h5>
              <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body container">
              <!--
              <div class='row p-2' v-if='burnModel.geometry && burnModel.geometry != null'>
                <div class='col-3 text-end' ><span class='form-label form-control-sm fw-bold '>Longitude/Latitude:</span></div>
                <div class='col'><input disabled type="text" class="form-control form-control-sm" :value="getBurnModelLonLat()"/></div>
              </div>
              -->
              <div class='row p-2'>
                <div class='col-3 text-end'>
                  <span class='form-label form-control-sm fw-bold'>Burn Name:</span>
                  <span class='text-danger'>*</span>
                </div>
                <div class='col'>
                  <VField v-model='burnModel.name' id='name' name='name' class="form-control form-control-sm" placeholder='(Required, 50 characters max)' minlength="1" maxlength="50"/>
                  <ErrorMessage class='text-danger' name='name'/>
                </div>
              </div>
              <div class='row p-2'>
                <div class='col-3 text-end'>
                  <span class='form-label form-control-sm fw-bold '>Burn Window:</span>
                  <span class='text-danger'>*</span>
                </div>
                <div class='col'>
                  <div class="form-check form-check-inline form-control-sm">
                    <VField v-model='burnModel.burnWindow' id='burnWindow1' name="burnWindow" type="radio" class='form-check-input' value="Growing"></VField>
                    <label class='form-check-label' for='burnWindow1'>Growing</label>
                  </div>
                  <div class="form-check form-check-inline form-control-sm">
                    <VField v-model='burnModel.burnWindow' id='burnWindow2' name="burnWindow" type="radio" class='form-check-input' value="Dormant"></VField>
                    <label class='form-check-label' for='burnWindow2'>Dormant</label>
                  </div>
                  <div class="form-check form-check-inline form-control-sm">
                    <VField v-model='burnModel.burnWindow' id='burnWindow3' name="burnWindow" type="radio" class='form-check-input' value="Year-round"></VField>
                    <label class='form-check-label' for='burnWindow3'>Year-round</label>
                  </div>
                  <div><ErrorMessage class='text-danger' name="burnWindow"/></div>
                </div>
              </div>
              <div class='row p-2'>
                <div class='col-3 text-end'>
                  <span class='form-label form-control-sm fw-bold'>Planned Acres:</span>
                  <span class='text-danger'>*</span>
                </div>
                <div class='col'>
                  <VField v-model='burnModel.plannedAcres' name='plannedAcres' type="number" class="form-control form-control-sm"/>
                  <ErrorMessage class='text-danger' name='plannedAcres'/>
                </div>
              </div>
              <div class='row p-2' v-if='!modelLoading'>
                <div class='col-3 text-end'>
                  <span class='form-label form-control-sm fw-bold '>State:</span>
                  <span class='text-danger'>*</span>
                </div>
                <div class='col'>
                  <!--
                  <VField v-model='burnModel.state' as='select' name='state' class='form-select form-control-sm' aria-label='State'>
                    <option></option>
                    <option v-for='state in states' :key='state.fips' :value="state.name">{{ state.name }}</option>
                  </VField>
                  <ErrorMessage class='text-danger' name='state'/>
                  -->
                  <select id='state' name='state' v-model='burnModel.state' class='form-select form-control-sm' aria-label='State'>
                    <option></option>
                    <option v-for='state in states' :key='state.fips' :value="state.name">{{ state.name }}</option>
                  </select>
                </div>
              </div>
              <div class='row p-2' v-if='!modelLoading'>
                <div class='col-3 text-end'>
                  <span class='form-label form-control-sm fw-bold'>Locality:</span>
                  <span class='text-danger bold' style=''>*</span>
                </div>
                <div class='col'>
                    <VField v-model='burnModel.locality' type="text" name='locality' class="form-control form-control-sm"></VField>
                    <ErrorMessage class='text-danger' name='locality'/>
                </div>
              </div>
              <div class='row p-2'>
                <div class='col-3 text-end'><span class='form-label form-control-sm fw-bold'>Status:</span></div>
                <div class='col'>
                  <div class="form-check form-check-inline form-control-sm">
                    <VField v-model='burnModel.burnStatus' id='burnStatus1' name="burnStatus" type="radio" class='form-check-input' value="Active"></VField>
                    <label class='form-check-label' for='burnStatus1'>Active</label>
                  </div>
                  <div class="form-check form-check-inline form-control-sm">
                    <VField v-model='burnModel.burnStatus' id='burnStatus2' name="burnStatus" type="radio" class='form-check-input' value="Inactive"></VField>
                    <label class='form-check-label' for='burnStatus2'>Inactive</label>
                  </div>
                  <ErrorMessage class='text-danger' name=""/>
                </div>
              </div>
              <div class='row p-2'>
                <div class='col-3 text-end' ><span class='form-label form-control-sm fw-bold '>Priority:</span></div>
                <div class='col'>
                  <div class="form-check form-check-inline form-control-sm">
                    <VField v-model='burnModel.priority' id='priority1' name="priority" type="radio" class='form-check-input' value="Low"></VField>
                    <label class='form-check-label' for='priority1'>Low</label>
                  </div>
                  <div class="form-check form-check-inline form-control-sm">
                    <VField v-model='burnModel.priority' id='priority2' name="priority" type="radio" class='form-check-input' value="Normal"></VField>
                    <label class='form-check-label' for='priority2'>Normal</label>
                  </div>
                  <div class="form-check form-check-inline form-control-sm">
                    <VField v-model='burnModel.priority' id='priority3' name="priority" type="radio" class='form-check-input' value="High"></VField>
                    <label class='form-check-label' for='priority3'>High</label>
                  </div>
                  <ErrorMessage class='text-danger' name="priority"/>
                </div>
              </div>
              <div class='col d-flex justify-content-center form-text fst-italic text-danger'>
                <span class='fs-6'>*</span>
                <span class='ps-1 fs-6'>Fields marked with an asterisk are required</span>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary btn-rounded btn-red" data-bs-dismiss="modal" @click='handleReset($event, onClickBurnModelCancel)'>Cancel</button>
              <!-- <button type="button" class="btn btn-primary btn-rounded btn-green" :disabled='!burnModelValidationResults || !burnModelValidationResults.meta || !burnModelValidationResults.meta.valid' data-bs-dismiss="modal" @click='onClickBurnModelSave'>Save</button> -->
              <button type="submit" value='Save' class="btn btn-primary btn-rounded btn-green" form='editBurnForm' :disabled='isSaveBurnDisabled()'>Save</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </VForm>
  <div class="fade modal" id="successModal" ref="successModal" tabindex="-1" aria-labelledby="sucessModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" style='rgb(127, 63, 20);'>
          <h5 class="modal-title">Burn Unit Saved</h5>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body container">
          <!-- <div class='row'>
            <div class='col'><span class='form-label form-control-lg fw-bold'>Burn Unit Saved!</span></div>
          </div> -->
          <div class='row'>
            <div class='col pt-3 ps-5 pe-5 pb-3'>
              <span>Your burn unit has been saved.</span>
              <span class='ps-1'>You can now manage parameters for optimal burn conditions.</span>
              <span class='ps-1'>To manage parameters go to the "Burn Manager" tab.</span>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-rounded btn-red" data-bs-dismiss="modal">Dismiss</button>
          <button type="button" class="btn btn-primary btn-rounded btn-green" data-bs-dismiss="modal" @click='onClickSuccessModalManageArea'>Manage Unit</button>
        </div>
      </div>
    </div>
  </div>
  <div id='burnMap' class='burnMap' ref='burnMap' :style='mapStyle'></div>
  <div style='display:none;'>
    <div class='ol-unselectable ol-control ol-search m-0 p-0' id='searchControl' ref='searchControl'>
      <div class="border input-group ol-search-input-group p-0 m-0 ps-2 pe-1">
        <form class='ol-search-form' v-on:submit.prevent>
          <input class='ol-search-input' v-model='search' type="text" placeholder="Search by Locality, Zip, or Lat Long" @click='onSearchInputClick' @focus='onSearchInputFocus' @keydown='onSearchInputKeyDown'/>
          <button class='ol-search-submit' type='submit' @click='onSearchButtonClick' style='height: 10px;width: 10px;'>
            <font-awesome-icon icon="search" />
          </button>
        </form>
        <div v-if='showSearches' class="cards" style='width:320px;'>
          <div
            class="card-search ui fluid"
            v-for="search in searches"
            :key="search.id"
            style="width: 100%;"
          >
            <div class='content card-search-content ps-1' @click='onSearchItemClick(search.value)'>
              {{ search.value }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="infoPopupContainer" ref='infoPopupContainer' class="ol-popup">
      <div class='container row m-0 p-0 mb-3'>
        <div class='col m-0 p-0'>
          <span class='fw-bold'>Burn Details</span>
        </div>
        <div class='col-1 text-end'>
          <a href="#" id="infoPopupCloser" ref='infoPopupCloser' class="ol-popup-closer"></a>
        </div>
      </div>
      <div id="infoPopupContent" ref='infoPopupContent'>
        <span class='form-label fw-bold me-2'>Name:</span>
        <router-link class='active-burns-router-link-plain' :to='{ name: "BurnArea", params: { id: infoPopupFeatureId }}'>{{ infoPopupFeatureName }}</router-link>
      </div>
    </div>
    <div id="popupContainer" ref='popupContainer' class="ol-popup">
      <div class='container row m-0 p-0 mb-3'>
        <div class='col'>
          <span class='fw-bold'>New Burn Unit</span>
        </div>
        <div class='col-1 text-end'>
          <a href="#" id="popupCloser" ref='popupCloser' class="ol-popup-closer" @click='onClickPopupClear'></a>
        </div>
      </div>
      <div id="popupContent" class='text-center' ref='popupContent'>
        <button id='saveAsBurnPointButton' ref='saveAsBurnPointButton' class='btn btn-primary btn-rounded btn-green' @click='onClickPopupSave' data-bs-toggle="modal" data-bs-target="#editBurnModal">Save as Burn Unit</button>
        <button id='clearFeatureButton' ref='clearFeatureButton' class='btn btn-primary btn-rounded mt-2 mb-2' @click='onClickPopupClear'>Clear Feature</button>
      </div>
    </div>
    <button id='drawBurnPointButton' ref='drawBurnPointButton' class='btn btn-primary btn-rounded fs-6' style='display: block;' type='button'>Draw Burn Unit</button>
    <button id='cancelBurnPointButton' ref='cancelBurnPointButton' class='btn btn-primary btn-rounded fs-6' style='display: none;' type='button'>Cancel Draw</button>
  </div>
</template>
<script>
/* eslint-disable no-shadow */
import { defineComponent, ref } from 'vue';
import { mapState, useStore } from 'vuex';
import { Modal } from 'bootstrap';
import { Form as VForm, Field as VField, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import 'ol/ol.css';
import 'ol-layerswitcher/dist/ol-layerswitcher.css';
import { WKT } from 'ol/format';
import BurnMapHelper from '@/mixins/burnMapHelper';
import BurnScoreHelper from '@/mixins/burnScoreHelper';
import DateHelper from '@/mixins/dateHelper';
import DirectionHelper from '@/mixins/directionHelper';
import PreferredConditionHelper from '@/mixins/preferredConditionHelper';
import SearchControl from '@/mixins/searchControl';

// https://softauthor.com/vuejs-composition-api-search-bar-using-computed-properties/
// https://getbootstrap.com/docs/5.0/components/toasts/
// https://vuejsexamples.com/vue-js-toast-notification-plugin-for-vue-3/
// https://www.codeply.com/p/3MCJfGYfJi

export default defineComponent({
  name: 'BurnMap',
  mixins: [
    BurnMapHelper,
    BurnScoreHelper,
    DateHelper,
    DirectionHelper,
    PreferredConditionHelper,
    SearchControl,
  ],
  props: {},
  components: {
    VForm,
    VField,
    ErrorMessage,
  },
  computed: {
    ...mapState(['userName', 'userEmail']),
    ...mapState('App', {
      burnMapConfig: (state) => state.burnMapConfig,
      burnUnitMapConfig: (state) => state.burnUnitMapConfig,
      states: (state) => state.states,
    }),
    ...mapState('BurnArea', {
      geocodeResults: (state) => state.geocodeResults,
      myBurn: (state) => state.myBurn,
      myBurns: (state) => state.myBurns,
      myNewBurn: (state) => state.myNewBurn,
      myStats: (state) => state.myStats,
      reverseGeocodeResult: (state) => state.reverseGeocodeResult,
    }),
    mapStyle() {
      // const style = { cursor: 'default' };
      // if (this.drawing) {
      //   style.cursor = 'pointer';
      // }
      // return style;
      return {};
    },
  },
  data() {

    const burnModel = {
      name: null,
      burnWindow: null,
      plannedAcres: null,
      state: null,
      locality: null,
      burnStatus: 'Active',
      priority: 'Normal',
      geometry: null,
    };

    const burnModelDefaults = {
      burnStatus: 'Active',
      priority: 'Normal',
    };

    const burnModelSchema = {
      name: yup.string().required('Burn name is required').min(1).max(50),
      burnWindow: (value) => {
        if (value) {
          return true;
        }
        return 'You must choose a burn window';
      },
      plannedAcres: yup.number('Required').moreThan(0).typeError('Please enter a number greater than 0'),
      state: yup.string().required('State is required'),
      locality: yup.string().required('Locality is required'),
    };

    return {

      burnConditions: null,
      burnId: null,
      burnMap: null,
      burnModel,
      burnModelDefaults,
      burnModelSchema,
      burnModelValidationResults: null,

      burnStyleActive: null,
      burnStyleActiveReadyToBurn: null,
      burnStyleInactive: null,
      burnStyleUnspecified: null,

      drawing: false,
      drawingInteraction: null,
      drawingVectorSource: null,
      drawingVectorLayer: null,

      drawBurnPointButtonVisible: true,
      cancelBurnPointButtonVisible: false,

      editBurnModal: null,

      formLoading: true,
      modelLoading: true,
      lonLat: null,

      infoPopupClickEventKey: null,
      infoPopupContainer: null,
      infoPopupContent: null,
      infoPopupCloser: null,
      infoPopupOverlay: null,
      infoPopupFeatureId: null,
      infoPopupFeatureName: null,

      myBurnConditions: [],

      popupContainer: null,
      popupContent: null,
      popupCloser: null,
      popupOverlay: null,

    };
  },
  watch: {
    // eslint-disable-next-line
    geocodeResults: function() {
      if (this.geocodeResults && this.geocodeResults.candidates && this.geocodeResults.candidates.length > 0) {
        const extent = this.geocodeResults.candidates[0].extent;
        this.burnMap.getView().fit(
          [extent.xmin, extent.ymin, extent.xmax, extent.ymax],
          this.burnMap.getSize(),
        );
      }
    },
    // eslint-disable-next-line
    myNewBurn: function(value) {

      const wkt = new WKT();

      const feature = wkt.readFeature(
        value.fields.Geometry.wkt,
        {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857',
        },
      );

      feature.setId(value.id);

      feature.setProperties({
        id: value.id,
        name: value.name,
        burnStatus: value.fields.burnStatus,
        burnCondition: value.fields.burnCondition,
      });

      if (value.fields.burnStatus === 'Active') {
        this.activeBurnsLayer.getSource().addFeature(feature);
      } else {
        this.inactiveBurnsLayer.getSource().addFeature(feature);
      }

    },
  },
  mounted() {
    console.debug('Enter mounted()');

    // console.debug(window.btoa('<svg class="layer-switcher-img" height="512" width="512" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path class="" fill="currentColor" d="M12.41 148.02l232.94 105.67c6.8 3.09 14.49 3.09 21.29 0l232.94-105.67c16.55-7.51 16.55-32.52 0-40.03L266.65 2.31a25.607 25.607 0 0 0-21.29 0L12.41 107.98c-16.55 7.51-16.55 32.53 0 40.04zm487.18 88.28l-58.09-26.33-161.64 73.27c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.51 209.97l-58.1 26.33c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 276.3c16.55-7.5 16.55-32.5 0-40zm0 127.8l-57.87-26.23-161.86 73.37c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.29 337.87 12.41 364.1c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 404.1c16.55-7.5 16.55-32.5 0-40z"></path></svg>'));
    // console.debug(window.btoa('<svg xmlns="http://www.w3.org/2000/svg" class="layer-switcher-img" width="576" height="512" viewBox="0 0 576 512"><path class="" fill="white" d="M12.41 148.02l232.94 105.67c6.8 3.09 14.49 3.09 21.29 0l232.94-105.67c16.55-7.51 16.55-32.52 0-40.03L266.65 2.31a25.607 25.607 0 0 0-21.29 0L12.41 107.98c-16.55 7.51-16.55 32.53 0 40.04zm487.18 88.28l-58.09-26.33-161.64 73.27c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.51 209.97l-58.1 26.33c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 276.3c16.55-7.5 16.55-32.5 0-40zm0 127.8l-57.87-26.23-161.86 73.37c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.29 337.87 12.41 364.1c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 404.1c16.55-7.5 16.55-32.5 0-40z"></path></svg>'));
    if (this.$route.query && this.$route.query.burnId) {
      this.burnId = this.$route.query.burnId;
    }

    this.editBurnModal = new Modal('#editBurnModal');

    this.$store.dispatch('BurnArea/getStats').then(() => {
      this.$store.dispatch('BurnArea/getBurns').then(() => {
        this.setBurnConditions();
        this.load();
      });
    });

    console.debug('Exit mounted()');
  },
  methods: {},
  setup() {

    const store = useStore();

    const search = ref(String());
    const searches = ref([]);
    const showSearches = ref(false);

    const hideSearches = () => {
      showSearches.value = false;
    };

    const onSearchInputClick = () => {
      searches.value = store.getters['SearchHistory/getMySearches'];
      showSearches.value = true;
    };

    const onSearchInputFocus = () => {
      searches.value = store.getters['SearchHistory/getMySearches'];
      showSearches.value = true;
    };

    const onSearchInputKeyDown = () => {
      showSearches.value = true;
    };

    const onSearchButtonClick = () => {
      showSearches.value = false;
      store.commit('SearchHistory/addMySearch', search.value);
      searches.value = store.getters['SearchHistory/getMySearches'];
      store.dispatch('BurnArea/fetchGeocodeResults', search.value).then(() => {
        //
      });
    };

    const onSearchItemClick = (searchValue) => {
      search.value = searchValue;
      showSearches.value = false;
      store.commit('SearchHistory/addMySearch', search.value);
      searches.value = store.getters['SearchHistory/getMySearches'];
      store.dispatch('BurnArea/fetchGeocodeResults', search.value).then(() => {
        //
      });
    };

    return {
      search,
      searches,
      showSearches,
      hideSearches,
      onSearchInputClick,
      onSearchInputFocus,
      onSearchInputKeyDown,
      onSearchButtonClick,
      onSearchItemClick,
    };
  },
});
</script>
